
$primary: #0772B9;
$body-color: #4f4f4f;
$light: #ddd;

:root {
  --muted: #C9C9C9;
  --border-muted: #eee
}

@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@700&family=Roboto:wght@900&display=swap');

// removes the grab cursor on the park canvas
.a-grab-cursor { cursor: default !important; }
// but still allows the cursor to change to pointer when hovering
.a-grab-cursor.a-mouse-cursor-hover {
  cursor: pointer !important;
}

body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

h1, h2, h3, h4, h5, h6, .btn {
  font-family: 'Roboto', sans-serif;
}

.btn {
  border-radius: 0px;
  font-weight: 500;
}

// dat.gui debug
.dg.ac {
  z-index: 1;
}

.red-text {
  color: red;
}

.z10 {
  z-index: 10;
  position: relative;
}

.invisible {
  display: none;
}

.z-index-1 {
  position: relative;
  z-index: 1;
}

.rs-base {
  z-index: 1000;
  top: 100px;
  left: 0;
  width: 25%;
  height: 70%;
}

.rs-counter-id {
  width: 150px;
}

.Toastify__toast--info {
  background: #0772B9 !important;
}
.Toastify__close-button {
  align-self: flex-end !important;
}





html, body {
  height: 100%;
}













.ReactRoot {

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  
  @media screen and (orientation: portrait) {
    width: 100%;
    height: 100%;
  }

  #hud {
    width: 100%;
    height: 100%;
  }

  .loading-screen {

    background: black;
    z-index: 100;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 1;
    transition: opacity 0.5s linear;
    height: 100%;
    width: 100%;

    &.dissolve {
      opacity: 0.0;
    }

    .container-fluid {
      height: 100%;
      width: 100%;
    }

    #loading-logo {
      height: 40vh;
      max-height: 500px;
    }
  
    #loading-text {
      margin: 14px;
      color: white;
      font-size: 28px;
      text-align: center;
      font-weight: bold;
    }
  
    #loading-bar {
      margin-top: 10%;
      margin: 0 auto;
      @media screen and (min-width: 991.98px) {
        margin: 0 auto;
      }
    }

  }

}

